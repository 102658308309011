import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import FooterCopyrights from "../components/Footer/FooterCopyrights";
import FooterLogos from "../components/Footer/FooterLogos";
import FooterNavBars from "../components/Footer/FooterNavBars";
import Header from "../components/Header/Header";

const PageNotFound = () => {
  return (
    <>
      <Header />
      <section className={`banner h-screen relative${module?.extraClass ? ' ' + module?.extraClass : ""}`}>
        <div className='banner-img h-full flex items-end justify-start'>
          <StaticImage
            src="../../static/images/404.webp"
            alt="404 Not Found"
            className="img"
            layout="fullWidth"
            placeholder="blurred"
            width={1920}
            height={1920}
          />
          <div className='banner-second-img absolute bottom-0 left-0'>
            <StaticImage
              src="../../static/images/banner-elephant.webp"
              alt="Icon Image"
              className='w-[400px] object-contain mdscreen:w-full'
            />
          </div>
          <div className="w-full justify-between flex mdscreen:flex-col mdscreen:justify-start">
            <div className='banner-info absolute top-[65%] px-15 text-left z-9 lg:pl-140 max-w-[950px] pb-50 pagenotfound'>
              <h1 className='lg:text-55 lg:leading-60 xlscreen:text-[45px] xlscreen:leading-45 lgscreen:text-35 mdscreen:text-26 mdscreen:leading-27 lgscreen:mb-5 text-white uppercase'>404</h1>
              <p>
              Sorry about that, it appears this page cannot be found. Please visit our home page to begin your trip around Africa.
              </p>
              <div className='btn-custom flex flex-wrap items-center space-x-8 mt-20'>
                <Link to="/" className="button banner-btn-transparent">
                Go Back To Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer" id="footer">
        <FooterNavBars />
        <FooterLogos />
        <FooterCopyrights />
      </footer>
    </>
  )
}

export default PageNotFound;
export const Head = () => <title>404 | Timeless Africa Safaris</title>